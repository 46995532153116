html, body {
  height: 100%;
  width: 100%;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.bold {
  font-weight: bold;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.clear {
  clear: both;
}

.menu-link {
  text-decoration: none;
}

.home-link {
  color: white;
}

.loading-context-bloc {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  text-align: center;
}

.entity-name {
  font-size: 1.2rem;
  margin-left: 1rem;
  text-transform: uppercase;
}

.customer-name {
  margin-left: 1rem;
  text-transform: uppercase; 
}

.icon-profil {
  margin-left: 1rem;
}

.breadcrumb {
  list-style: none;
  margin: 0;
  height: 1rem;
  padding: 0;
  color: grey;
}

.breadcrumb li {
  float: left;
  font-size: 0.7rem;
  margin-right: 0.5rem;
}

.breadcrumb li div {
  padding-top: 0.3rem;
  padding-left: 2rem;
  position: relative;
}

.breadcrumb li div.breadWithIcon {
  padding-left: 2rem;
}

.breadcrumb li div.breadWithoutIcon {
  padding-left: 0rem;
}

.breadcrumb li div.breadcrumb-sep {
  padding-left: 0rem;
}

.breadcrumb li svg {
  position: absolute;
  top: 0;
  left: 0;
}

.breadcrumb a {
  text-decoration: none;
  color: black;
}

.list-field-header {
  margin-bottom: 1rem;
  margin-top: 1rem;
  clear: both;
}

.list-field-title {
  display: inline-block;
  margin: 0.5rem;
  color: grey;
  font-size: 0.8rem;
}

.list-field-title svg {
  font-size: 16px !important;
  float: left;
  margin-right: 0.5rem;
}

.object-info-line {
  margin: 1rem;
  font-size: 0.7rem;
  color: grey;
}

.read-item-container {
  margin-top: 2rem;
}

.form-read-field {
  margin-bottom: 1rem;
}

.form-read-field-label {
  color: grey;
}

.form-read-field-value {
  display: inline-block;
}

.form-input-label {
  color: rgba(0, 0, 0, 0.54);
  font-size: 0.75rem;
}

.table-col-action {
  width: 200px;
}

.embeddedlist-row {
  clear: both;
  margin-bottom: 1rem;
  min-height: 3rem;
}

.embeddedlist-col {
  float: left;
  min-width: 200px;
}

.embeddedlist-col-title {
  color: grey;
  font-size: 0.7rem;
  margin-bottom: 0.5rem;
}

.form-edit-field {
  margin: 1rem;
  display: inline-block;
}

.form-edit-field-container {
  margin-left: 1rem;
  margin-right: 1rem;
}

.page-header {
  margin-bottom: 2rem;
}

.page-footer {
  margin-top: 2rem;
}

.embedded_object-content {
  margin-top: 1rem;
}

.embedded_object-content .form-read-field {
  font-size: 0.8rem;
}

.menu-part-toptitle {
  text-align: center;
  margin-top: 0.5rem;
  background-color: #e0e0e040;
  padding: 0.3rem;
  border-radius: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  color: #616161;
  font-size: 0.8rem;
}

.legende {
  font-size: 0.7rem;
  color: grey;
}

.offline {

}

.state-pastille {
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 10px;  
}

.offline .state-pastille, .pastille-default {
  background-color: #e1e1e1;
}

.online .state-pastille, .pastille-success {
  background-color: #B8E986;
}

.wferror .state-pastille, .pastille-error {
  background-color: red;
}

.pastille-warning {
  background-color: orange;
}

.line {
  margin-bottom: 1rem;
}

.subline {
  margin-bottom: 0.3rem;
}

tr.collapsed, tr.collapsed > td {
  height: 0;
  display: none;
}

.raw-part-title {
  font-size: 1rem;
  font-weight: normal;
  text-decoration: underline;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
  clear: both;
}

.raw-part-data {
  float: left;
  margin: 0.5rem;
}

.live-content .legende {
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: center;
}

.live-content .form-read-field-label {
  font-size: 0.8rem;
}

.state-field {
  display: flex;
  align-items: center;
}

.state-field .state-pastille {
  margin-right: 0.5rem;
}

.item-read-part-title-bloc {
  margin-top: 1rem;
}

.item-read-part-title {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.item-read-part-title-text {
  margin-left: 1rem;
}

.toolbar-content {
  position: relative;
  height: 100%;
}

.toolbar-footer {
  position: absolute;
    bottom: 1rem;
    text-align: center;
    width: 100%;
}

.kpi-title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.kpi-bloc {
  text-align: left;
  font-size: 0.8rem;
}

.kpi-bloc-count-line {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.kpi-bloc-count {
  padding: 0.2rem;
  text-align: center;
  background-color: #cac6c62b;
  font-weight: bold;
  border-radius: 0.5rem;
  margin-right: 0.5rem;
  flex: 1;
  height: 60px;
  display: flex;
  flex-direction: column;
}

.kpi-bloc-count.success {
  color: #43af00;
}

.kpi-bloc-count.error {
  color: #f50000;
}

.kpi-bloc-count.notstarted {
  color: #909090;
}

.kpi-bloc-count-label {
  font-size: 0.7rem;
  font-weight: normal;
  flex: 1;
}

.kpi-bloc-count-value {
    font-size: 1.2rem;
    flex: 1;
}

.kpi-energy-line {
  padding: 0.2rem;
  text-align: center;
  background-color: #cac6c608;
  font-weight: bold;
  border-radius: 0.5rem;
  margin-right: 0.5rem;
  flex: 1;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start
}

.kpi-energy-line-bloc {
  padding: 0.2rem;
  color: #0C76FE;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.kpi-energy-line-bloc-label {
  font-size: 0.7rem;
  font-weight: normal;
  color: #757575;
}

.kpi-energy-line-bloc-value {
  font-size: 1.2rem;
}

.kpi-energy-line-bloc-value .unit {
  font-size: 0.8rem;
  display: block;
}

.kpi-footer {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.pageContent {
  margin-top: 2rem;
  clear: both;
}

.kpi-chart-bloc-label {
  margin-right: 0.5rem;
  background-color: #cac6c62b;
  padding: 0.5rem;
  font-weight: bold;
  border-radius: 0.3rem;
}

.kpi-chart-bloc-chart {
  padding: 1rem;
}

.kpi-content-part-label {
  font-weight: bold;
    font-size: 1.5rem;
    color: #0C76FE;
    background-color: #cac6c608;
    padding: 0.5rem;
    border-radius: 0.3rem;
    margin-bottom: 1rem;
}