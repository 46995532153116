.u2fLogo {
    width: 180px;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.u2Container {
    text-align: center;
    position: absolute;
    top: 0;
    z-index: 0;
    width: 100%;
    padding-top: 5rem;
}

.u2f-instruction {
    margin-top: 1rem !important;
    font-weight: bold !important;
    /*animation: blinker 1s linear infinite;*/
}

.u2f-error {
    margin-top: 1rem !important;
    font-weight: bold !important;
    color: red !important;
}

.color-red {
    color: red;
}

.form-title {
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.form-error {
    padding: 1rem !important;
    font-weight: bold !important;
    color: red !important;
}

.login-container {
    position: relative;
}

.form-login {
  position: relative;
  z-index: 1;
}

.form-login .u2Container {
    padding-top: 8rem;
}

.success-validation-msg {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.success-icon {
    color: green;
}

@keyframes blinker {
    100% {
        opacity: 50%;
    }
}